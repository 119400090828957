import { defineStore } from 'pinia'
import { ref } from 'vue'
import { useRoute } from 'vue-router'

export const useTranslationStore = defineStore('translation', () => {
  const translations = ref<any | null>(null)

  const setTranslation = (newTranslation: any) => {
    translations.value = newTranslation
  }

  /**
   * Checks if the query parameter `showTranslationKeys` is set to `true`.
   */
  const shouldShowTranslationKeys = () => {
    const route = useRoute()
    return route?.query?.showTranslationKeys === 'true'
  }

  /**
   * Fetches a specific translation from the store.
   *
   * @param key Key to look for in translations.
   * @param def The default value to return, if none is found
   */
  const getTranslation: (
    key: string,
    variables?: Record<string, any>
  ) => string = (key, variables = {}) => {
    if (shouldShowTranslationKeys()) {
      return ` ${key}`.toUpperCase()
    }

    let translation = key
      .split('.')
      .reduce((prev: any, curr: any) => prev && prev[curr], translations.value)

    for (const [varKey, varValue] of Object.entries(variables)) {
      translation = translation.replace(`{${varKey}}`, varValue)
      translation = translation.replace(`:${varKey}`, varValue)
    }

    return translation || `${key}`.toUpperCase()
  }

  return { translations, setTranslation, getTranslation }
})
