import useUserStore from '../stores/user'
import { UserState } from '../types/userTypes'

export default defineNuxtRouteMiddleware(async (to, from) => {
  const userStore = useUserStore()
  const config = useRuntimeConfig()

  const loginPath = config.public.LOGIN_URL
  if (userStore.userState === 'Anonymous') {
    try {
      const response = await $fetch('/api/auth/validate')
      const userAttributes = {
        ...response?.willowUser?.attributes.reduce((acc, attr) => {
          acc[attr.Name] = attr.Value
          return acc
        }, {}),
        accessToken: response?.willowUser?.accessToken,
        refreshToken: response?.willowUser,
      }

      userStore.setUser(userAttributes)

      const brandCode = config?.public.BRAND_CODE.toUpperCase()
      const isWebSubscriber = userAttributes['custom:subscribed_brands']
      const subscriptionNumber = userAttributes['custom:subscription_number']

      // Updated logic to check isWebSubscriber and set UserState
      if (
        isWebSubscriber?.includes(brandCode) ||
        ['PAS', 'IVP', 'IFP', 'GDP', 'HIP']?.some(subscriber =>
          isWebSubscriber?.includes(subscriber)
        )
      ) {
        userStore.setUserState(UserState.subscriber)
        await userStore.getFavourites(userAttributes.sub)
      } else if (!subscriptionNumber) {
        userStore.setUserState(UserState.JustRegister)
      } else {
        userStore.setUserState(UserState.ExpiredSubscriber)
      }
    } catch {
      userStore.setUser(null)
      userStore.setUserState(UserState.anonymous)
    }
  }
  if (to.path === loginPath && userStore.userState === UserState.subscriber) {
    return navigateTo('/')
  }
})
